"use client";

import {
  Bar,
  Line,
  BarChart,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import html2canvas from "html2canvas";
import { useRef } from "react";

interface ChartData {
  label: string;
  data: number;
}

interface ChartProps {
  data: ChartData[];
  color?: string;
  type?: "bar" | "line";
}

const chartConfig = {
  data: {
    label: "Value",
    color: "hsl(var(--chart-1))",
  },
};

export function Chart({
  data,
  color = "hsl(var(--chart-1))",
  type = "bar",
}: ChartProps) {
  const chartRef = useRef<HTMLDivElement>(null);

  const handleExport = async () => {
    if (!chartRef.current) return;

    try {
      const canvas = await html2canvas(chartRef.current, {
        backgroundColor: null,
        scale: 10,
        onclone: (document: Document) => {
          document.body.style.fontFamily = "Arial, Helvetica, sans-serif";
        },
      });

      const link = document.createElement("a");
      link.download = `${type}-chart-export.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();
    } catch (error) {
      console.error("Error exporting chart:", error);
    }
  };

  // Transform the data to match the expected format
  const transformedData = data.map((item) => ({
    name: item.label,
    value: item.data,
  }));

  const ChartComponent = type === "bar" ? BarChart : LineChart;
  const DataComponent = type === "bar" ? Bar : Line;

  return (
    <div className="flex flex-col items-end gap-4">
      <div
        ref={chartRef}
        className="min-h-[350px] w-full border border-border rounded-lg py-4 pr-6 pt-8 mt-4 bg-background"
      >
        <ChartContainer config={chartConfig} className="w-full">
          <ChartComponent data={transformedData}>
            <CartesianGrid vertical={false} className="stroke-muted" />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              className="text-sm"
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              className="text-sm"
            />
            <ChartTooltip content={<ChartTooltipContent />} />
            {type === "bar" ? (
              <Bar dataKey="value" fill={color} radius={[4, 4, 0, 0]} />
            ) : (
              <Line dataKey="value" stroke={color} dot />
            )}
          </ChartComponent>
        </ChartContainer>
      </div>
      <Button
        onClick={handleExport}
        variant="outline"
        size="sm"
        className="flex gap-2 items-center"
      >
        <Download size={14} />
        Export as PNG
      </Button>
    </div>
  );
}
