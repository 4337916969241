"use client";

import { StreamableValue, useStreamableValue } from "ai/rsc";
import Markdown from "react-markdown";
import { Copy } from "lucide-react";
import { useState } from "react";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { useChat } from "@/app/contexts/ChatContext";

function CodeBlock({
  children,
  className,
}: {
  children: string;
  className?: string;
}) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative group/code">
      <pre className={className}>
        <code>{children}</code>
      </pre>
      <button
        onClick={handleCopy}
        className="absolute -bottom-2 -right-6 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 opacity-0 group-hover/code:opacity-100 transition-opacity"
        title="Copy code"
      >
        <Copy
          className={`h-3 w-3 ${copied ? "text-foreground" : "text-gray-500"}`}
        />
      </button>
    </div>
  );
}

function MarkdownSection({ content }: { content: string }) {
  const [copied, setCopied] = useState(false);
  const { sendMessage } = useChat();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="group/section ">
      {content.length > 0 && (
        <div className="mx-3 mt-4">
          <Markdown
            components={{
              code({ children, className }) {
                if (className) {
                  return (
                    <CodeBlock className={className}>
                      {children as string}
                    </CodeBlock>
                  );
                }
                return <code className={className}>{children}</code>;
              },
              a({ href, children }) {
                if (href?.startsWith("#option-")) {
                  return (
                    <>
                      <Button
                        variant="outline"
                        className="mr-2 mt-2 flex"
                        onClick={() => {
                          sendMessage(children as string);
                        }}
                      >
                        {children}
                      </Button>
                    </>
                  );
                }
                return <a href={href}>{children}</a>;
              },
            }}
          >
            {content}
          </Markdown>
          <div className=" opacity-0 group-hover/section:opacity-100 transition-opacity">
            <button
              onClick={handleCopy}
              className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
              title="Copy section"
            >
              <Copy
                className={`h-3 w-3 ${
                  copied ? "text-foreground" : "text-gray-500"
                }`}
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export function AIMessage(props: { value: StreamableValue<string> }) {
  const [data] = useStreamableValue(props.value);
  const sections = data?.split(/\n\s*[-_*]{3,}\s*\n/) || [];

  return (
    <div className="empty:hidden rounded-lg max-w-[600px] space-y-4">
      {sections.map((section, index) => (
        <div key={index} className="w-full">
          <MarkdownSection content={section.trim()} />
          {index < sections.length - 1 && <Separator className="mt-4 " />}
        </div>
      ))}
    </div>
  );
}
