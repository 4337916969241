"use client";

import * as React from "react";
import { Compass, ArrowRight, X } from "lucide-react";
import { createPortal } from "react-dom";
import ReactMarkdown from "react-markdown";
import { GeistSans } from "geist/font/sans";
import { Button } from "@/components/ui/button";
export function JiraCard({ content, url }: { content: string; url: string }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const renderPortal = () => {
    const chatCanvas = document.getElementById("chat-canvas");
    if (!chatCanvas) return null;

    return createPortal(
      <div className="bg-background p-6 w-[800px] ">
        <div className="w-[800px] absolute top-2 right-0 pl-4 ">
          <Button
            variant="outline"
            size="icon"
            onClick={toggleOpen}
            className="hover:bg-muted rounded-full h-7 w-7 "
          >
            <X size={16} />
          </Button>
        </div>
        <ReactMarkdown
          className={`mr-4 mt-4 prose dark:prose-invert markdown ${GeistSans.className}`}
        >
          {content}
        </ReactMarkdown>{" "}
      </div>,
      chatCanvas,
    );
  };

  return (
    <>
      <div
        onClick={toggleOpen}
        className="p-4 border border-border mx-2 rounded-lg max-w-[300px] truncate flex gap-2 items-center text-muted-foreground hover:bg-muted transition-colors cursor-pointer"
      >
        <Compass size={16} />
        <span className="truncate max-w-[220px] text-sm">{url}</span>
        <ArrowRight size={16} />
      </div>
      {isOpen && renderPortal()}
    </>
  );
}
