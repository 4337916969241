import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Node, useGraphStore } from "@/components/graph/index";
import { getEntityTypes } from "@/app/actions/graph-actions";
import Property from "./property";
import { getColorForLabel } from "../utils";
import { useEffect, useState } from "react";
import { Separator } from "@/components/ui/separator";
import { getNodeDocuments } from "@/app/actions/graph-actions";

type IGraphSidebarProps = {
  node: Node | null;
  handleLoadEntity: (entity: string) => void;
  vectorStoreId: number;
};

export default function GraphSidebar({
  node,
  handleLoadEntity,
  vectorStoreId,
}: IGraphSidebarProps) {
  const [entityTypes, setEntityTypes] = useState<string[]>([]);
  const { hoveredEntity, setHoveredEntity } = useGraphStore();
  const [activeTab, setActiveTab] = useState<string>("graph");
  const [nodeDocuments, setNodeDocuments] = useState<any[]>([]);
  useEffect(() => {
    const fetchEntityTypes = async () => {
      const entityTypes = await getEntityTypes(vectorStoreId);
      let entityTypesArray: string[] = [];
      for (const entityType of entityTypes) {
        entityTypesArray.push(entityType.entityType);
      }
      setEntityTypes(entityTypesArray);
    };
    fetchEntityTypes();
  }, []);

  useEffect(() => {
    const fetchNodeDocuments = async () => {
      //@ts-ignore
      const documents = await getNodeDocuments(vectorStoreId, node?.dbId);

      documents.forEach((document) => {
        document.metadata = JSON.parse(document.metadata || "");
      });
      setNodeDocuments(documents);
      console.log("documents", documents);
    };
    fetchNodeDocuments();
  }, [node]);

  useEffect(() => {
    setActiveTab(node ? "node" : "graph");
  }, [node]);

  return (
    <div
      className={`absolute bottom-0  right-0 z-10 h-full w-96 border-l border-input bg-background overflow-y-scroll `}
    >
      <Tabs
        value={activeTab}
        defaultValue="graph"
        onValueChange={setActiveTab}
        className="w-full p-4 "
      >
        <TabsList className="w-full">
          <TabsTrigger value="node" className="w-full">
            Node
          </TabsTrigger>
          <TabsTrigger value="graph" className="w-full">
            Graph
          </TabsTrigger>
        </TabsList>
        <TabsContent value="node" className="overflow-y-auto">
          {node ? (
            <div className="pt-2 overflow-y-scroll overflow-x-clip h-full">
              <h2 className="text-xl font-bold mb-4 text-ellipsis w-80 overflow-hidden">
                {node.id}
              </h2>

              {nodeDocuments.length > 0 && (
                <div className="space-y-4">
                  <h4 className=" font-semibold mt-4 mb-2">Documents:</h4>

                  {nodeDocuments.map((document, index) => (
                    <div className="text-sm" key={index}>
                      <div className="text-xs text-muted-foreground flex items-center justify-between gap-2 mb-2">
                        {document.document}

                        <span className="text-xs">
                          Page {document.metadata.loc.pageNumber}/
                          {document.metadata.pdf.totalPages}
                        </span>
                      </div>
                      <div className="break-words">{document.content}</div>
                      <Separator className="my-2" />
                    </div>
                  ))}
                </div>
              )}

              {/* <h3 className=" font-semibold mt-4 mb-2">Labels:</h3> */}
              <ul className=" flex flex-wrap gap-2 mb-6">
                {node?.labels?.map((label, index) => (
                  <li
                    key={index}
                    style={{ backgroundColor: getColorForLabel(label) }}
                    className="px-2 py-1 rounded-lg cursor-pointer dark:text-slate-800"
                    onMouseEnter={() => setHoveredEntity(label)}
                    onMouseLeave={() => setHoveredEntity(null)}
                    onClick={() => handleLoadEntity(label)}
                  >
                    {label}
                  </li>
                ))}
              </ul>

              {/* <h3 className=" font-semibold mt-4 mb-2">Properties:</h3> */}
              {/* <div className="space-y-4">
                                {node && Object.entries(node.properties).map(([key, value]) => (
                                    <Property key={key} name={key} value={String(value)} />
                                ))}
                            </div> */}
            </div>
          ) : (
            <>
              <p className="pt-4">Select a node</p>
            </>
          )}
        </TabsContent>
        <TabsContent value="graph">
          <div className="pt-2 overflow-y-auto overflow-x-clip h-full">
            <h3 className="font-semibold mt-4 mb-2">Entity Types</h3>
            <ul className="flex flex-wrap gap-2 mb-6">
              {entityTypes.length > 0 ? (
                <>
                  {entityTypes.map((entityType, index) => (
                    <li
                      key={index}
                      style={{ backgroundColor: getColorForLabel(entityType) }}
                      className="px-2 py-1 rounded-lg cursor-pointer dark:text-slate-800"
                      onMouseEnter={() => setHoveredEntity(entityType)}
                      onMouseLeave={() => setHoveredEntity(null)}
                      onClick={() => handleLoadEntity(entityType)}
                    >
                      {entityType}
                    </li>
                  ))}
                </>
              ) : (
                <span>No entity types found</span>
              )}
            </ul>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}
