function HSLToRGB(h: number, s: number, l: number, opacity?: number): string {
    s /= 100;
    l /= 100;
    const k = (n: number) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return `rgba(${Math.round(255 * f(0))}, ${Math.round(255 * f(8))}, ${Math.round(255 * f(4))}, ${opacity || 1})`;
}

export function getColorForLabel(label: string, opacity?: number): string {
    let hash = 0;
    for (let i = 0; i < label.length; i++) {
        const char = label.charCodeAt(i);
        hash = ((hash << 5) - hash) + char + 1;
        hash = hash & hash;
    }

    // Adjust hue range to favor blues and greens
    const hue = (Math.abs(hash) + 10) % 360; // Range: 120-360 (greens to blues)

    // Increase saturation range
    const saturation = 40 + Math.abs((hash >> 8) % 30); // 40-70%

    // Adjust lightness for more light colors
    const lightness = 55 + Math.abs((hash >> 16) % 30); // 60-85%

    return HSLToRGB(hue, saturation, lightness, opacity);
}