"use client";

import * as React from "react";
import { FileText, ArrowRight, X } from "lucide-react";
import { createPortal } from "react-dom";
import ReactMarkdown from "react-markdown";
import { GeistSans } from "geist/font/sans";
import { Button } from "@/components/ui/button";

interface Document {
  id: number;
  content: string;
  metadata: string;
  similarity?: unknown;
}

interface DocumentsCardProps {
  documents: Document[];
  query: string;
}

export function DocumentsCard({ documents, query }: DocumentsCardProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const renderMetadata = (metadata: string) => {
    if (!metadata) return null;

    try {
      // Attempt to parse the metadata string as JSON
      const parsedMetadata = JSON.parse(metadata);
      return (
        <div className="flex flex-wrap gap-2 text-xs text-muted-foreground mb-2">
          {Object.entries(parsedMetadata).map(([key, value]) => (
            <span key={key} className="bg-muted px-2 py-1 rounded">
              {key}: {String(value)}
            </span>
          ))}
        </div>
      );
    } catch {
      // If metadata is not valid JSON, display it as a single string
      return (
        <div className="flex flex-wrap gap-2 text-xs text-muted-foreground mb-2">
          <span className="bg-muted px-2 py-1 rounded">
            Metadata: {metadata}
          </span>
        </div>
      );
    }
  };

  const renderPortal = () => {
    const chatCanvas = document.getElementById("chat-canvas");
    if (!chatCanvas) return null;

    return createPortal(
      <div className="bg-background p-6 w-[800px]">
        <div className="w-[800px] absolute top-2 right-0 pl-4">
          <Button
            variant="outline"
            size="icon"
            onClick={toggleOpen}
            className="hover:bg-muted rounded-full h-7 w-7"
          >
            <X size={16} />
          </Button>
        </div>
        <div className="mr-4 mt-4">
          {documents.map((doc, index) => (
            <div key={doc.id || index}>
              {renderMetadata(doc.metadata)}

              <ReactMarkdown
                className={`prose dark:prose-invert markdown ${GeistSans.className} text-sm`}
              >
                {doc.content}
              </ReactMarkdown>
              {index < documents.length - 1 && (
                <div className="my-4 border-t border-border" />
              )}
            </div>
          ))}
        </div>
      </div>,
      chatCanvas,
    );
  };

  return (
    <>
      <div
        onClick={toggleOpen}
        className="mt-2 p-4 border border-border rounded-lg mx-2 max-w-[300px] truncate flex gap-2 items-center text-muted-foreground hover:bg-muted transition-colors cursor-pointer"
      >
        <FileText size={16} />
        <span className="truncate max-w-[220px] text-sm">
          
          {query}
        </span>
        <ArrowRight size={16} />
      </div>
      {isOpen && renderPortal()}
    </>
  );
}
