'use client'
import React, { useCallback, useRef, useState, useEffect } from 'react';

import ForceGraph2D, { ForceGraphMethods, NodeObject, LinkObject } from 'react-force-graph-2d';
import ForceGraph3D from 'react-force-graph-3d';
import { renderNode, renderNode3D } from './node';
import { renderLink } from './link';
import { Maximize2, Minimize2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import GraphSidebar from './sidebar/sidebar';
import * as THREE from 'three';
import { create } from 'zustand';
import { getConnectedNodes } from '@/app/actions/graph-actions';

type IGraphProps = {
    graph: GraphData;
    vectorStoreId: number;
}

export interface Node {
    id: string;
    type: string;
    x?: number;
    y?: number;
    color?: string;
    loading?: boolean;
    labels?: string[];
    properties?: any;
}

interface Link {
    source: string;
    target: string;
    type: string;
}

interface Graph {
    nodes: Node[];
    links: Link[];
}


export interface GraphData {
    nodes: any[]
    links: any[]
}
interface GraphState {
    hoveredEntity: string | null
    setHoveredEntity: (entity: string | null) => void
}

export const useGraphStore = create<GraphState>((set) => ({
    hoveredEntity: null,
    setHoveredEntity: (entity) => set({ hoveredEntity: entity })
}))



const Graph: React.FC<IGraphProps> = ({ graph: initialGraph, vectorStoreId }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const fg2DRef = useRef<ForceGraphMethods<NodeObject<Node>, LinkObject<Node, Link>>>(undefined);
    const fg3DRef = useRef<any>(undefined); // Type for 3D graph ref
    const [isExpanded, setIsExpanded] = useState(false);
    const [dimensions, setDimensions] = useState({ width: 680, height: 600 });
    const [graph, setGraph] = useState<Graph>(initialGraph);
    const [loadingNode, setLoadingNode] = useState<string[] | null>(null);
    const [selectedNode, setSelectedNode] = useState<Node | null>(null);
    const { hoveredEntity } = useGraphStore();
    const [is3D, setIs3D] = useState(false);

    useEffect(() => {
        const updateDimensions = () => {
            if (isExpanded && containerRef.current) {
                const width = window.innerWidth - 384;
                const height = window.innerHeight;
                setDimensions({ width, height });
            } else {
                setDimensions({ width: 680, height: 600 });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [isExpanded]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const loadEntities = async (entity: string) => {

        try {
            const response = await fetch('/api/graph/entity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ entityType: entity }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Entity API response:", data);

            if (!data.nodes || data.nodes.length === 0) {
                console.log(`No nodes found for entity type: ${entity}`);
                return;
            }

            // Transform the nodes to match the expected format
            const transformedNodes = data.nodes.map((node: any) => ({
                id: node.id,
                labels: node.labels,
                properties: node.properties,
            }));

            setGraph(prevGraph => {
                const existingNodeIds = new Set(prevGraph.nodes.map(n => n.id));
                const newNodes = transformedNodes.filter((n: Node) => !existingNodeIds.has(n.id));
                return {
                    nodes: [...prevGraph.nodes, ...newNodes],
                    links: prevGraph.links
                };
            });
        } catch (error) {
            console.error("Error fetching entity data:", error);
            return [];
        }


    }

    const handleClick = async (node: NodeObject<Node>) => {

        // Set the clicked node as loading
        setLoadingNode(prevLoadingNodes => {
            if (prevLoadingNodes === null) {
                return [node.id];
            } else {
                return [...prevLoadingNodes, node.id];
            }
        });

        setSelectedNode(node);

        try {
            const newData = await getConnectedNodes(vectorStoreId, node.dbId);
            console.log("New data:", newData);

            // Merge the new data with the existing graph#
            //@ts-ignore
            setGraph(prevGraph => {
                const existingNodeIds = new Set(prevGraph.nodes.map(n => n.id));
                const newNodes = newData.nodes.filter(n => n.id !== node.id && !existingNodeIds.has(n.id));

                const existingLinkIds = new Set(prevGraph.links.map(l => `${l.source}-${l.target}-${l.type}`));
                const newLinks = newData.links.filter(l => !existingLinkIds.has(`${l.source}-${l.target}-${l.type}`));

                return {
                    nodes: [...prevGraph.nodes, ...newNodes],
                    links: [...prevGraph.links, ...newLinks],
                };
            });

            // Remove the clicked node from loadingNode
            setLoadingNode(prevLoadingNodes => {
                if (prevLoadingNodes === null) {
                    return null;
                } else {
                    return prevLoadingNodes.filter(id => id !== node.id);
                }
            });

            setTimeout(() => {

                // Center the graph on the clicked node
                if (fg2DRef.current && node.x !== undefined && node.y !== undefined) {
                    fg2DRef.current.centerAt(node.x, node.y, 600);
                }
            }, 600);

        } catch (error) {
            console.error("Error fetching linked nodes:", error);
        }
    }


    const nodeCanvasObject = useCallback((node: NodeObject<Node>, ctx: CanvasRenderingContext2D, globalScale: number) => {
        renderNode(node, ctx, globalScale, loadingNode, hoveredEntity);
    }, [loadingNode, hoveredEntity]);

    const nodeCanvasObect3D = useCallback((node: NodeObject<Node>) => {
        return renderNode3D(node, loadingNode, hoveredEntity);
    }, [loadingNode, hoveredEntity]);

    const handleNodeClick = (node: NodeObject<Node>) => {
        handleClick(node);
    };

    // Add this new function to determine link color based on theme
    const getLinkColor = useCallback(() => {
        // Check if we're in dark mode by looking at the document element
        const isDarkMode = document.documentElement.classList.contains('dark');
        return isDarkMode ? '#6b7280' : '#6b7280'; // lightgrey in dark mode, gray in light mode
    }, []);

    return (
        <div
            ref={containerRef}
            className={`relative transition-all duration-300 ease-in-out ${isExpanded ? '!fixed top-0 left-0 inset-0 z-50 bg-background' : 'w-[680px] h-[600px]'}`}
        >
            {isExpanded && (
                <div className={`absolute top-4 left-4 z-10 flex items-center space-x-2 ${is3D ? 'text-white' : ''}`}>
                    <span className="text-sm font-medium">2D</span>
                    <Switch
                        checked={is3D}
                        onCheckedChange={setIs3D}
                    />
                    <span className="text-sm font-medium">3D</span>
                </div>
            )}

            {!is3D ? (
                <ForceGraph2D
                    ref={fg2DRef}
                    graphData={graph}
                    onNodeClick={handleNodeClick}
                    nodeLabel="id"
                    linkLabel="type"
                    width={dimensions.width}
                    height={dimensions.height}
                    nodeCanvasObject={nodeCanvasObject}
                    nodeCanvasObjectMode={() => 'replace'}
                    linkColor={getLinkColor}
                />
            ) : (
                <ForceGraph3D
                    ref={fg3DRef}
                    graphData={graph}
                    onNodeClick={handleNodeClick}
                    nodeLabel="id"
                    linkLabel="type"
                    width={dimensions.width}
                    height={dimensions.height}
                    linkAutoColorBy="type"
                    nodeResolution={16}
                    nodeThreeObject={nodeCanvasObect3D}
                />
            )}

            {isExpanded && (
                <GraphSidebar node={selectedNode} handleLoadEntity={loadEntities} vectorStoreId={vectorStoreId} />
            )
            }

            <Button
                variant="ghost"
                onClick={toggleExpand}
                className="absolute bottom-4 left-4 z-10"
            >
                {isExpanded ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
            </Button>
        </div >
    );
};

export default React.memo(Graph);