"use client";

import React from "react";
import { Button } from "./ui/button";
import { useChat } from "@/app/contexts/ChatContext";
import { MousePointer2 } from "lucide-react";
import { useState } from "react";

interface UserOptionsProps {
  options: { option: string; description: string }[];
}

export const UserOptions: React.FC<UserOptionsProps> = ({ options }) => {
  const { sendMessage } = useChat();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOptionSelect = (option: string) => {
    sendMessage(option);
    setSelectedOption(option);
  };

  return (
    <div className="flex flex-col gap-2 mx-2 w-full">
      <div className="grid grid-cols-2 gap-2 w-full">
        {options.map((option) => (
          <Button
            key={`option-${option.option}`}
            variant="outline"
            className={`flex w-full text-left justify-start align-top items-start hover:bg-primary/10 whitespace-normal h-auto gap-3 ${selectedOption === option.option ? "bg-primary/10" : ""}`}
            onClick={() => handleOptionSelect(option.option + ": \n\n" + option.description)}
          >
            <MousePointer2 className="w-4 h-4 mt-1 flex-shrink-0" />
            <div>
              <h3 className="break-words font-medium">{option.option}</h3>
              <p className="text-xs text-muted-foreground font-normal">
                {option.description}
              </p>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};
